<template>
  <div>
    <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border width="width:100%; height:50%">
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="orderno" label="订单编号" width="300"></el-table-column>
      <el-table-column prop="custid" label="客户" width="100"></el-table-column>
      <el-table-column prop="vifplanid" label="验证计划" width="300"></el-table-column>
      <el-table-column prop="rebuildflag" label="改造标记" width="100"></el-table-column>
      <el-table-column prop="breakflag" label="损坏标记" width="100"></el-table-column>
      <el-table-column prop="status" label="状态" width="50"></el-table-column>
      <el-table-column prop="scope" label="操作" min-width="100"></el-table-column>
    </el-table>
    <el-pagination @current-change="devlogChange"
                   :current-page="page.pageNum"
                   :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                   :total="page.total">
    </el-pagination>
  </div>
</template>

<script>
import ORDER from "../../api/biz/api_order";
import syscomm from '../../utils/syscomm';
import COMM from "../../api/base/api_comm";

export default {
  name: "orderfinalizelist", // 定稿列表
  data() {
    return {
      showLoading: false,
      page: {list: [], pageNum: 1, pageSize: 10, total: 0}
    }
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      ORDER.list({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.showLoading = false;
        if (200 == res.code) {
          var data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    }
  }
}
</script>

<style scoped>

</style>